<template>
    <div class="mb-8">
        <div class="flex justify-between">
            <el-select v-model="receiver" class="w-40" @change="changeFilters">
                <el-option value="client" :label="$t('help_desk.clients')" />
                <el-option value="employee" :label="$t('help_desk.employees')" />
                <el-option value="manager" :label="$t('help_desk.managers')" />
            </el-select>
            <el-select v-model="language" class="w-20" @change="changeFilters">
                <el-option value="se" label="SE" />
                <el-option value="en" label="EN" />
                <el-option value="pl" label="PL" />
            </el-select>
        </div>
    </div>
</template>
<script>
import queryString from 'query-string';

export default {
    data() {
        return {
            language: 'se',
            receiver: 'client',
        };
    },

    created() {
        const { language, receiver } = queryString.parse(window.location.search);
        this.language = language || 'se';
        this.receiver = receiver || 'client';
    },

    methods: {
        changeFilters() {
            const query = queryString.parse(window.location.search, { sort: false });

            query.language = this.language;
            query.receiver = this.receiver;

            this.$router.replace({
                query,
            }).catch(() => {});

            this.$emit('changed');
        },
    },
};
</script>
